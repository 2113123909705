<template>
  <div
    id="app"
    class="thank-you-layout"
  >
    <header class="thank-you-layout__header">
      <main-menu thanks />
    </header>
    <main class="thank-you-layout__content">
      <div>
        <nuxt-page />
      </div>
    </main>

    <Footer />
  </div>
</template>

<script lang="ts">
import MainMenu from '@/core/components/menu/MainMenu.vue'
import Footer from '@/core/components/footer/Footer.vue'

export default defineNuxtComponent({
  components: {
    MainMenu,
    Footer
  }
})
</script>

<style lang="scss" scoped>
.thank-you-layout {
  &__main-finder {
    @apply w-full;
    @apply z-20;

    @screen lg {
      @apply w-1085p;
      @apply mx-auto;
    }
  }

  &__content {
    @apply pt-2 pb-2;
    min-height: 30vh;

    @screen lg {
      @apply pt-12 pb-2;
    }
  }

  &__header {
    @apply pt-3;
    @apply px-2;
    @apply bg-white;
    @screen lg {
      @apply p-0;
      @apply m-0;
      @apply bg-transparent;
    }
  }
  &__footer {
    @apply mt-50p;
  }
}
</style>
